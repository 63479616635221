@use "../../../abstract/variables" as *;

.nav-and-content {
    display: flex;
}

.content {
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}