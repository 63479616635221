/* CreateAccount.scss */
.CreateAccount {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    background-color: #f0f0f0;
  }
  
  .create-account-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 60%;
    
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 24px;
  }
  
  .create-account-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  .create-account-label {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
    display: block;
  }
  
  .create-account-input {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
  }
  
  .password-hint {
    font-size: 12px;
    color: #888;
    margin-top: 8px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .submit {
    background-color: #6200ee;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .submit:hover {
    background-color: #3700b3;
  }
  
  .error-message {
    font-size: 14px;
    color: red;
    text-align: center;
    margin-bottom: 16px;
  }
  