@use "../../../abstract/variables" as *;

.SingleField {
    width: 100%;
    height: 100%;
    background-color: $lightGrey;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.field-name-container {
    @include centered(column, centered);
}

.field-name-container :hover {
 cursor: pointer;
}

.controller-id {
    margin: 0;
}

.field-name {
    background-color: $white;
    width: 15vw;
    height: 5vh;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    @include centered(column, centered);
    justify-content: center;
    margin: 0;
}

.singlefield-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}


.configure-button {
    background-color: $lightGreen;
    width: 15%;
    color: #fff;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: #45a049;
    }
}

.delete-field-button {
    background-color: $darkRed;
    width: 15%;
    color: #fff;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: $lightRed;
    }
}

.add-zone-button {
    background-color: $white;
    width: 15%;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    &:hover {
        background-color: $lightGrey;
        border: 1px solid black;
    }
}

.dropdowns-container {
    display: flex;
    gap: 2vw;
}