@use "../../../abstract/variables" as *;

.PipesBar {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates a grid with 3 columns of equal width */
  gap: 10px; /* Adjust the gap between grid items */
  height: auto; /* Adjust the height as needed */
  width: 90%;
  margin-bottom: 5vh;
}

.pipe-column {
  height: 25px;
  border-radius: 2px;
  border: 2px solid $darkGreen;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  &:hover {
    border-color: white;
    cursor: pointer; /* Change border color on hover */
  }
}

.pipe-left,
.pipe-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 100%; /* Adjust font size as needed */
}

.pipe-left {
  border-right: 1px solid #fff; /* Create visual split */
}

.closed {
  background-color: red; /* Color when gate is closed */
}

.open {
  background-color: #0096c7; /* Color when gate is open */
}

.pipe-column:last-child {
  margin-right: 0;
}
