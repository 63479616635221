.history-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.history-list {
    list-style-type: none;
    padding: 0;
}

.history-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.history-date {
    font-size: 14px;
    color: #888;
    margin-bottom: 8px;
}

.history-content {
    font-size: 16px;
    color: #333;
}
