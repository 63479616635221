.TopNav {
  background: linear-gradient(to right, #3A5A40, #588157); /* Use a gradient background */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box-shadow */
  color: #FBFAF5;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute space between the logo and the button */
  padding: 0 20px; /* Add some padding for spacing */
  position: relative;
}

.sungate-logo {
  margin: 0;
  position: relative; /* Remove absolute positioning */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  flex-grow: 1; /* This allows the logo to take up available space */
}

.logout-button {
  background-color: #3A5A40;
  color: #FBFAF5;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  margin-left: auto; /* Ensure the button is aligned to the right */
}

.logout-button:hover {
  background-color: #344E41; /* Darken the button on hover */
  transform: scale(1.05); /* Slightly enlarge the button on hover */
}

.logout-button:active {
  background-color: #344E41;
  transform: scale(0.95); /* Slightly shrink the button when clicked */
}

.logout-button:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 0 3px rgba(52, 78, 65, 0.5); /* Add a custom focus ring */
}/*# sourceMappingURL=TopNav.css.map */