@use "../../../abstract/variables" as *;

.AddField {
    margin-top: 5vh;
    width: 50%;
    height: 70%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 24px;
    }

    .form-group {
        margin-bottom: 15px;
        label {
            display: block;
            margin-bottom: 5px;
            font-weight: 500;
        }
        input {
            width: 98%;
            padding: 8px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
            outline: none;
            transition: border-color 0.3s ease-in-out;
            &:focus {
                border-color: $lightGreen;
            }
        }
    }

    button[type="submit"] {
        background-color: $lightGreen;
        width: 15%;
        color: #fff;
        padding: 10px 16px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        &:hover {
            background-color: #45a049;
        }
    }
}