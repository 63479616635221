/* AddSchedule.scss */
.AddSchedule {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
}

.zone-options {
    background-color: #f0f0f0;
    /* Default background color */
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.selected {
        background-color: #3498db;
        /* Blue background color when selected */
    }
}

.zone-box {
    width: 100%;
    border: 1px solid black;
}

.options-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 80vw;
    height: 60vh;
}

.sequence-box {
    width: 100%;
    border: 1px solid black;
}

.sequence-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw;
}

.duration-container {
    display: flex;
    flex-direction: column;
}