@use "../../../abstract/variables" as *;

.listcomponent{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $lightGrey;
}

.list-container {
    height: 85vh;
    width: 170px;
    border-right: 1px solid $lightGrey;
    margin: 0;
}

