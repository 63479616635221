@use "../../../abstract/variables" as *;

.SingleZone {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;

  .zone-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .pipe-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  .pipe-bars {
    display: flex;
    justify-content: center;
    gap: 20%;
    width: 75%;
    height: 70%;
    background-color: $lightGrey;
    border-radius: 5px;

    .pipe-content {
      display: flex; /* Create a flex container for the pipes */
      flex-wrap: wrap; /* Allow pipes to wrap within the container */
      gap: 20px; /* Add spacing between pipes */
      justify-content: center;
      width: 100%;
      height:100% /* Take full width of the container */
    }

    .pipe-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .pipe-bar {
      width: 5vw;
      background-color: white;
      border: 2px solid #27ae60;
      height: 70%;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .pipe-name {
      font-size: 0.8rem;
    }

    .pipe-button {
      margin-top: 5px;
      padding: 5px 10px;
      background-color: $lightGreen;
      color: white;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &.on {
        background-color: #e74c3c; /* Red when on */
      }

      &:hover {
        background-color: $mediumGreen;
      }
    }
  }
  .toggle-all-button {
    margin-bottom: 10px; // Add spacing between the button and the pipe-bars
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #2980b9;
    }
  }
}