@use "../../../abstract/variables" as *;

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
    text-align: center;

    .pipe-name {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333;
    }

    .left-right-section-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }

    .left-section,
    .right-section {
        width: 45%;

        .percentage-title {
            font-size: 18px;
            margin-bottom: 10px;
            color: #555;
        }

        .update-title {
            font-size: 16px;
            margin-bottom: 8px;
        }

        .percentage-input {
            padding: 8px;
            margin-bottom: 10px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 16px;
        }

        .update-button,
        .toggle-button {
            width: 100%;
            padding: 10px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
            margin-bottom: 20px;

            &:hover {
                background-color: #0096FF;
            }

            &:disabled {
                background-color: #ADD8E6; /* Greyed-out color */
                cursor: not-allowed;
            }
        }

        .toggle-button {
            /* base styles for the button */
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            
            &.open-gate {
                background-color: red;
                color: white;
            }
            
            &.close-gate {
                background-color: #0096c7;
                color: white;
            }
        }

        .gate-toggle {
            .toggle-title {
                font-size: 16px;
                margin-bottom: 8px;
            }
        }
    }

    .rename-button,
    .close-button {
        width: 60%;
        padding: 10px;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;

        &:hover {
            background-color: darken(#6c757d, 10%);
        }

        &:disabled {
            background-color: lighten(#6c757d, 30%); /* Greyed-out color */
            cursor: not-allowed;
        }
    }

    .rename-button {
        background-color: #6c757d; /* Grey color */
    }

    .close-button {
        background-color: #dc3545;
        margin-top: 30px;

        &:hover {
            background-color: #c82333;
        }

        &:disabled {
            background-color: #e59b9b; /* Greyed-out color */
            cursor: not-allowed;
        }
    }

    .rename-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .rename-input {
            width: 80%;
            padding: 8px;
            margin-bottom: 10px;
            border-radius: 4px;
            border: 1px solid #ccc;
            font-size: 16px;
        }

        .update-button {
            width: 80%;
        }
    }
    .error-message {
        color: red;
        font-weight: bold;
    }
    
}
