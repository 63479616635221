@use "../../../abstract/variables" as *;

  .SingleSchedule {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .schedule-name {
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .schedule-table {
    border-collapse: collapse;
    width: 75%;
  }
  
  .table-header {
    border: 1px solid black;
    background-color: #f0f0f0; /* Gray background color for header cells */
    padding: 8px;
    text-align: left;
  }
  
  .table-row:nth-child(even) {
    background-color: #f5f5f5;
    border-right:1px solid black;
    border-bottom: 1px solid black; /* Alternate row background color */
  }

  .table-row:nth-child(odd) {
    border-right:1px solid black;
    border-bottom: 1px solid black; /* Alternate row background color */
  }
  
  .duration,
  .field,
  .zone {
    border: 1px solid black;
    padding: 8px;
  }
  
  .pipe {
    border: 1px solid black;
    padding: 4px;
    display: inline-block;
    margin-right: 4px;
  }

  .pipe-list {
    padding: 8px;
    display: inline-block;
  }

  .time{
    border-left:1px solid black;
    padding: 8px;
  }

  .start-and-table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .manual-progress,
  .manual-start{
    background-color: $lightGreen;
    height: 40px;
    color: #fff;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: #45a049;
    }
  }

  .start-time-container {
    display: flex;
    width: 74%;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Add more specific styles as needed for other elements in the table */
  