/* ZoneDropdown.scss */
.dropdown {
    position: relative;
    display: inline-block;
  
    .dropbtn {
      background-color: #04AA6D;
      color: white;
      padding: 16px;
      font-size: 16px;
      border: none;
    }
  
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
  
      .zone-dropdown-option {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
  
      .zone-dropdown-option:hover {
        background-color: #ddd;
        cursor: pointer;
      }
    }
  
    &.active .dropdown-content {
      display: block;
    }
  
    &.active .dropbtn {
      background-color: #3e8e41;
    }
  }
  