@use "../../../abstract/variables" as *;


.LogIn {
    height: 100vh;
    @include centered(column, center);
    background-color: #ECECEC;
}

.login-box {
    width: 40vw;
    height: 50vh;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    @include centered(column, centered);
    justify-content: center;
}

.login-form {
    width: 50%;
}

.form-group {
    margin-bottom: 20px;
}

.login-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.login-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.submit {
    margin-bottom: 10px;
}

.forgot-password {
    margin-top: 10px;
}

.link {
    color: #007bff;
    cursor: pointer;
    @include centered(column, center)
}